<template>
  <v-app>
    <Navbar
      :userfullname="authUser.name"
      :snackbar="snackbar"
      :actionColor="actionColor"
      :actionMessage="actionMessage"
      :role="authUser.type"
    />
    <v-main style="background-color: #f8f8f8">
      <div class="rounded-xl pa-4 ma-4 shadow-dreamy" style="background-color: #fdfdfd">
        <div class="media">
          <h1 class="subheading text--gray">
            Media
            <v-btn outlined color="primary" @click="showUploadDialog">
              <v-icon left>mdi-plus</v-icon>
              Upload a File
            </v-btn>
          </h1>

          <v-dialog
              v-model="dialog"
              transition="dialog-bottom-transition"
              scrollable
              max-width="600px"
              persistent
          >
            <v-card tile>
              <v-toolbar
                  flat
                  dense
                  dark
                  color="cyan"
                  src="https://api.staugustineshg.org/api/v2/files/bg2.png"
              >
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Upload a File</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row class="mt-4">
                    <v-col cols="12">
                      <v-file-input
                          v-model="newfile.file"
                          label="File*"
                          outlined
                          show-size
                          dense
                          counter
                          @change="toBase64"
                          :rules="rules"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-img
                  class="grey lighten-4 ma-1 elevation-3 rounded mx-6"
                  :src="
                        newfile.file != ''
                          ? imageData
                          : 'https://api.staugustineshg.org/api/v2/files/placeholder.png'
                      "
                  max-height="200px"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn
                    color="success darken-1"
                    :loading="saving"
                    :disabled="disabled || !isValid"
                    text
                    @click="submitFile"
                >
                  <v-icon left>mdi-content-save-edit-outline</v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-container class="my-5">
            <v-dialog v-model="loading" hide-overlay persistent width="300">
              <v-card color="cyan" dark>
                <v-card-text>
                  Please stand by
                  <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-card outlined class="rounded-lg" style="border-color: rgba(239,147,194,0.5);border-width: 1px">
              <v-toolbar dense flat>
                <template>
                  <v-tabs v-model="tab" align-with-title>
                    <v-tabs-slider color="#FF69B4FF"></v-tabs-slider>

                    <v-tab>All Files</v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat>
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          outlined
                          label="Search"
                          dense
                      ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                          :headers="headers"
                          :items="uploads"
                          :search="search"
                          :loading="loading"
                          loading-text="Loading... Please wait"
                      >
                        <template #item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  text
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  @click="copyURL(item.url)"
                                  small
                              >
                                <v-icon>content_copy</v-icon>
                              </v-btn>
                            </template>
                            <span
                            >Click to copy: {{ item.url }} to clipboard</span
                            >
                          </v-tooltip>
                        </template>
                        <template #item.preview="{ item }">
                          <v-card
                              class="ma-3"
                              :style="{
                            height: '100px',
                            width: '144px',
                            overflow: 'hidden',
                            backgroundImage: 'url(' + item.url + ')',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                          }"
                          >
                          </v-card>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-container>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Media",
  components: {
    Navbar,
  },

  data: () => ({
    tab: null,
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Url", value: "url" },
      { text: "Size (bytes)", value: "size", filterable: false },
      { text: "Actions", value: "actions", filterable: false },
      { text: "Preview", value: "preview", filterable: false },
    ],
    saving: false,
    disabled: false,
    loading: true,
    dialog: false,
    actionColor: "black",
    actionMessage: "",
    snackbar: false,
    imagetypes: ["jpg", "jpeg", "png"],
    newfile: {
      file: null,
    },
    message: "",
    imageData: "",
    rules: [
      (value) =>
        !value ||
        value.size < 10 * 1024 * 1024 ||
        "File size should be less than 10 MB!",
    ],
    authUser: {
      name: "",
    },
  }),

  computed: {
    ...mapGetters({
      uploads: "upload/UPLOADS",
      authenticated: "user/AUTHENTICATED",
    }),
    isValid() {
      return this.newfile.file?.size < 10 * 1024 * 1024;
    },
  },

  methods: {
    toBase64() {
      const reader = new FileReader();
      reader.readAsDataURL(this.newfile.file);
      reader.onload = (e) => {
        this.imageData = e.target.result;
      }
      reader.onerror = function (error) {
        console.log(error);
      }
    },
    showUploadDialog() {
      this.snackbar = false;
      this.dialog = true;
    },
    submitFile() {
      console.log(this.isValid);
      this.saving = true;
      this.disabled = true;

      let formData = new FormData();

      formData.append("file", this.newfile.file);

      axios
        .post("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.actionMessage = "Your file has been uploaded.";
          this.actionColor = "success";
          this.snackbar = true;
          this.saving = false;
          this.dialog = false;
          this.newfile.file = null;

          this.loading = true;

          this.$store.dispatch("upload/GET_FILES").then(() => {
            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
          this.actionMessage =
            "An error occured while uploading the file. Please try again";
          this.actionColor = "red";
          this.snackbar = true;
          this.saving = false;
          this.dialog = false;

          this.newfile.file = null;
        });
    },

    copyURL(url) {
      navigator.clipboard.writeText(url);
    },
  },

  mounted() {
    this.$store
      .dispatch("user/GET_STATE")
      .then(() => {
        this.$store.dispatch("upload/GET_FILES").then(() => {
          this.loading = false;
        });

        if (JSON.parse(localStorage.getItem("user"))) {
          this.authUser = JSON.parse(localStorage.getItem("user"));
        } else {
          this.$router.replace({
            name: "login",
          });
        }
      })
      .catch((err) => {
        this.actionMessage =
          err.message + "! Please refresh this page to retry.";
        this.actionColor = "red";
        this.snackbar = true;
        this.loading = false;

        setTimeout(() => {
          this.actionMessage = "";
          this.actionColor = "black";
          this.snackbar = false;
        }, 4000);
      });
  },
};
</script>
